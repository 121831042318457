import { Tooltip as ToolTipUI } from '@material-ui/core';

export default function Tooltip({ title, children, arrow = true, position = 'top', classes }) {
  return (
    <ToolTipUI
      title={title}
      placement={position}
      arrow={arrow}
      classes={{ tooltip: classes ? classes : '!p-2 !bg-white !text-slate-600/95 shadow !text-xs !max-w-[450px]', arrow: '!text-white' }}
    >
      {children}
    </ToolTipUI>
  );
}
